
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import MinorMetrics from "@/components/admin/dashboard/MinorMetrics.vue";
import SalesMetrics from "@/components/admin/dashboard/SalesMetrics.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "admin-dashboard",
  components: {
    MinorMetrics,
    SalesMetrics,
  },
  setup() {
    const summary = ref();

    const getOverview = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_OVERVIEW)
          .then(({ data }) => {
            //Assign data to form fields
            summary.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    onMounted(() => {
      setCurrentPageTitle("Administrator Dashboard");
      getOverview();
    });

    return { summary };
  },
});
