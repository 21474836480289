
import { defineComponent } from "vue";

export default defineComponent({
  name: "dashboard-minor-metrics-component",
  components: {},
  props: {
    summary: Object,
    className: { type: String, required: false },
    height: { type: String, required: false, default: "425px" },
  },
});
