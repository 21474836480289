import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "mb-5 col-4" }
const _hoisted_3 = { class: "mb-5 col-8 mb-xl-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MinorMetrics = _resolveComponent("MinorMetrics")!
  const _component_SalesMetrics = _resolveComponent("SalesMetrics")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MinorMetrics, {
        summary: _ctx.summary,
        className: "h-xl-100",
        height: "275px"
      }, null, 8, ["summary"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_SalesMetrics, {
        summary: _ctx.summary,
        className: "h-xl-100"
      }, null, 8, ["summary"])
    ])
  ]))
}