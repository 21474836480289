
import { defineComponent } from "vue";
import variables from "@/router/api";

export default defineComponent({
  name: "dashboard-policies-component",
  props: {
    policies: Array,
  },
  setup() {
    const addCommaToNumberString = (amount: number): string => {
      return variables.addCommaToNumberString(amount);
    };

    const formatDate = (dateString: string): string => {
      return variables.formatDate(dateString);
    };

    return { addCommaToNumberString, formatDate };
  },
});
