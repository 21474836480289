
import { defineComponent } from "vue";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "dashboard-payments-component",
  props: {
    payments: Array,
  },
  setup() {
    const { capitalize } = ResusableFunctions();

    const addCommaToNumberString = (amount: number): string => {
      return variables.addCommaToNumberString(amount);
    };

    const formatDate = (dateString: string): string => {
      return variables.formatDate(dateString);
    };

    return { capitalize, addCommaToNumberString, formatDate };
  },
});
