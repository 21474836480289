
import { defineComponent } from "vue";
import ResusableFunctions from "@/composables/ReusableFunctions";
import Payments from "@/components/admin/dashboard/Payments.vue";
import Policies from "@/components/admin/dashboard/Policies.vue";
import Customers from "@/components/admin/dashboard/Customers.vue";
import Claims from "@/components/admin/dashboard/Claims.vue";

export default defineComponent({
  name: "sales-metrics-component",
  props: {
    widgetClasses: String,
    summary: Object,
  },
  components: { Payments, Policies, Customers, Claims },
  setup() {
    const { isEmptyArray } = ResusableFunctions();
    return { isEmptyArray };
  },
});
